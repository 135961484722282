"use client"

import logError from "@/lib/utils/logError";
import useGlobalStore from "@/store/global.store";
import RichText from "@/ui/RichText";
import Button from "@apg.gg/core/lib/Button";
import { useTranslations } from "next-intl";
import { useEffect } from "react";
 
export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  const t = useTranslations();
  const { errorRetries, setErrorRetries } = useGlobalStore();
  const section = "home";

  useEffect(() => {
    if (!errorRetries[section as keyof typeof errorRetries]) {
      setErrorRetries({
        ...errorRetries,
        [section]: 0
      });
    }

    logError(error);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="row bg-black-800 pt-16 pb-8 sm:py-24">
      <div className="container">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col bg-black-900 px-9 py-8 gap-3 rounded-2xl items-center">
            {/* Content section */}
            <div className="flex flex-col w-full items-center gap-2">
              <p className="flex items-center gap-1">
                {t('Common.error-loading-this-section-description')}
              </p>

              {errorRetries[section as keyof typeof errorRetries] < 2 ? (
                <Button fontSize="sm" type="outline" onClick={() => {
                  setErrorRetries({
                    ...errorRetries,
                    [section]: errorRetries[section as keyof typeof errorRetries] + 1
                  });
                  reset();
                }}>
                  {t('Common.error-try-again-label')}
                </Button>
              ) : (
                <p className="text-sm">
                  <RichText>
                    {
                      t.rich('Common.error-report-to-discord-description', {
                        link: (chunks) => <a href="https://discord.com/channels/1037732149832466463/1157505835136716883" target="_blank" className="text-aqua">{chunks}</a>
                      })
                    }
                  </RichText>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}